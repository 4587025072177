<template>
  <div>
    <v-card id="bill-list">
      <v-card-text class="d-flex align-center pb-5">
        <v-row>
          <v-col
            cols="12"
            sm="3"
            lg="2"
          >
            <!-- create bill -->
            <v-btn
              :block="$vuetify.breakpoint.xsOnly"
              color="primary"
              class="me-3 bill-button"
              :to="{ name: 'bill-form' }"
            >
              <v-icon
                size="18"
                class="me-1"
              >
                {{ icons.mdiPlus }}
              </v-icon>
              <span>Create expense bill</span>
            </v-btn>
          </v-col>

          <v-spacer></v-spacer>

          <v-col
            cols="12"
            sm="3"
            class="pr-sm-0"
          >
            <div class="d-flex align-center">
              <!-- Date range -->
              <v-menu
                v-model="isDateMenuOpen"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    :append-icon="icons.mdiCalendar"
                    :value="dateRangeText"
                    readonly
                    dense
                    outlined
                    hide-details
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="dateRange"
                  color="primary"
                  :first-day-of-week="1"
                  range
                >
                  <v-spacer></v-spacer>
                  <div class="w-full text-right">
                    <v-btn
                      color="primary"
                      block
                      class="mb-2"
                      @click="dateRangeChange()"
                    >
                      Set dates
                    </v-btn>
                    <span
                      v-if="firstRecord"
                      class="first-record-button caption primary--text"
                      @click="dateRange = [firstRecord, `${yearNow}-${monthNow}-${dayNow}`]; dateRangeChange()"
                    >
                      From the very beginning
                    </span>
                  </div>
                </v-date-picker>
              </v-menu>
            </div>
          </v-col>

          <v-col
            cols="12"
            sm="3"
            lg="2"
            class="pr-sm-0"
          >
            <!-- filter status -->
            <v-select
              v-model="statusFilter"
              :items="statusOptions"
              single-line
              outlined
              dense
              hide-details
              clearable
              placeholder="Select Status"
              class="mr-0 mr-sm-3"
            ></v-select>
          </v-col>

          <v-col
            cols="12"
            sm="3"
            lg="3"
            class="d-flex pl-sm-0"
          >
            <!-- search bill -->
            <v-tooltip
              :left="$vuetify.breakpoint.smAndUp"
              :top="$vuetify.breakpoint.xsOnly"
              :nudge-bottom="$vuetify.breakpoint.smAndUp ? 20 : 0"
              :nudge-top="$vuetify.breakpoint.xsOnly ? 5 : 0"
              :nudge-right="$vuetify.breakpoint.xsOnly ? 100 : 0"
              max-width="250"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-avatar
                  size="30"
                  class="mr-3 my-auto hover-pointer d-none d-sm-block"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon size="25">
                    {{ icons.mdiAlertCircleOutline }}
                  </v-icon>
                </v-avatar>
              </template>
              <h3 class="my-2">
                How to use the search bar
              </h3>
              <p class="mb-1">
                You can search using:
              </p>
              <ul class="mb-2">
                <li>
                  Vendor name
                </li>
              </ul>
            </v-tooltip>
            <v-text-field
              v-model="searchText"
              :append-icon="icons.mdiMagnify"
              single-line
              dense
              outlined
              hide-details
              placeholder="Search Bill"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-text class="pt-5">
        <v-row>
          <v-col
            cols="4"
            sm="2"
          >
            <div class="d-flex align-center">
              <v-select
                v-model="tablePagination.per"
                :items="listLengthOptions"
                single-line
                outlined
                dense
                hide-details
                class="bill-list-row-selection"
                @input="listLengthChange()"
              ></v-select>
              <div class="pl-3">
                Rows
              </div>
            </div>
          </v-col>

          <v-spacer></v-spacer>

          <v-col
            sm="10"
            cols="4"
            class="d-flex justify-end"
          >
            <v-pagination
              v-model="tablePagination.current_page"
              total-visible="6"
              :length="tablePagination.total_page"
              @input="fetchBills"
            ></v-pagination>
          </v-col>
        </v-row>
      </v-card-text>

      <!-- table -->
      <v-data-table
        :headers="tableHeaders"
        :items="bills"
        :options.sync="options"
        :items-per-page="100"
        :loading="loading"
        mobile-breakpoint="0"
        hide-default-footer
        class="text-no-wrap fixed-action d-none d-sm-block"
      >
        <!-- Status -->
        <template #[`item.status`]="{item}">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-avatar
                size="30"
                :color="billStatus(item.status).variant"
                :class="`v-avatar-light-bg ${billStatus(item.status).variant}--text`"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon
                  size="18"
                  :color="billStatus(item.status).variant"
                >
                  {{ billStatus(item.status).icon }}
                </v-icon>
              </v-avatar>
            </template>
            <span>{{ item.status }}</span>
          </v-tooltip>
        </template>

        <!-- Notes -->
        <template #[`item.note`]="{item}">
          <div
            class="text-truncate py-1"
            style="width: 300px; max-height: 30px;"
            v-html="item.note"
          ></div>
        </template>

        <!-- Actions -->
        <template #[`item.actions`]="{item}">
          <v-menu
            bottom
            left
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                class="me-2"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item @click="payment.expense_bill_id = item.id, payment.max_amount = item.amount_due, isAddNewPaymentDialogOpen = true">
                <v-list-item-title>
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiPlus }}
                  </v-icon>
                  <span>Add Payment</span>
                </v-list-item-title>
              </v-list-item>

              <v-list-item :to="{ name: 'bill-form', params: { id: item.id } }">
                <v-list-item-title>
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiPencilOutline }}
                  </v-icon>
                  <span>View/Edit</span>
                </v-list-item-title>
              </v-list-item>

              <v-list-item @click="bill = item; isDuplicateDialogVisible = true">
                <v-list-item-title>
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiContentCopy }}
                  </v-icon>
                  <span>Duplicate</span>
                </v-list-item-title>
              </v-list-item>

              <v-divider></v-divider>

              <v-list-item @click="bill = item; isVoidDialogVisible = true">
                <v-list-item-title>
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiDeleteOutline }}
                  </v-icon>
                  <span>Void</span>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>

      <!-- table view for mobile -->
      <v-data-table
        :headers="tableSmHeaders"
        :items="bills"
        :options.sync="options"
        :items-per-page="100"
        :loading="loading"
        :expanded.sync="expanded"
        single-expand
        show-expand
        mobile-breakpoint="0"
        hide-default-footer
        class="text-no-wrap fixed-action d-block d-sm-none"
      >
        <!-- Invoice Number -->
        <template #[`item.serial_number`]="{item}">
          <router-link
            class="font-weight-medium text-decoration-none"
            :to="{ name: 'invoice-view', params: { id: item.id } }"
          >
            {{ item.invoice_number }}
          </router-link>
        </template>

        <!-- Status -->
        <template #[`item.status`]="{item}">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-avatar
                size="30"
                :color="billStatus(item.status).variant"
                :class="`v-avatar-light-bg ${billStatus(item.status).variant}--text`"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon
                  size="18"
                  :color="billStatus(item.status).variant"
                >
                  {{ billStatus(item.status).icon }}
                </v-icon>
              </v-avatar>
            </template>
            <span>{{ item.status }}</span>
          </v-tooltip>
        </template>

        <template v-slot:expanded-item="{ headers, item }">
          <td
            class="table-expand"
            :colspan="headers.length"
          >
            <div class="d-flex">
              <p class="mt-3">
                Vendor: {{ item.vendor_company }}
              </p>
              <v-spacer></v-spacer>
              <div class="fixed-expand-action">
                <v-menu
                  bottom
                  left
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
                    </v-btn>
                  </template>

                  <v-list>
                    <v-list-item @click="payment.expense_bill_id = item.id, payment.max_amount = item.amount_due, isAddNewPaymentDialogOpen = true">
                      <v-list-item-title>
                        <v-icon
                          size="16"
                          class="me-2"
                        >
                          {{ icons.mdiPlus }}
                        </v-icon>
                        <span class="caption">Add Payment</span>
                      </v-list-item-title>
                    </v-list-item>

                    <v-list-item :to="{ name: 'bill-form', params: { id: item.id } }">
                      <v-list-item-title>
                        <v-icon
                          size="16"
                          class="me-2"
                        >
                          {{ icons.mdiPencilOutline }}
                        </v-icon>
                        <span class="caption">View/Edit</span>
                      </v-list-item-title>
                    </v-list-item>

                    <v-list-item @click="bill = item; isDuplicateDialogVisible = true">
                      <v-list-item-title>
                        <v-icon
                          size="16"
                          class="me-2"
                        >
                          {{ icons.mdiContentCopy }}
                        </v-icon>
                        <span class="caption">Duplicate</span>
                      </v-list-item-title>
                    </v-list-item>

                    <v-divider></v-divider>

                    <v-list-item @click="bill = item; isVoidDialogVisible = true">
                      <v-list-item-title>
                        <v-icon
                          size="16"
                          class="me-2"
                        >
                          {{ icons.mdiDeleteOutline }}
                        </v-icon>
                        <span class="caption">Void</span>
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </div>
            </div>
            <p>Date Issued: {{ item.date_issued }}</p>
            <p>Due Date: {{ item.due_date }}</p>
            <p
              class="text-wrap"
              v-html="item.note"
            ></p>
          </td>
        </template>
      </v-data-table>

      <!-- Save payment dialog -->
      <v-dialog
        v-model="isAddNewPaymentDialogOpen"
        width="500"
      >
        <v-card>
          <v-card-title class="d-flex align-center mv-4">
            Add Payment
            <v-spacer></v-spacer>
            <v-btn
              icon
              small
              @click="isAddNewPaymentDialogOpen = false; payment.payment_amount = null"
            >
              <v-icon size="22">
                {{ icons.mdiClose }}
              </v-icon>
            </v-btn>
          </v-card-title>

          <v-card-text>
            <v-form v-model="savePaymentValid">
              <v-menu
                v-model="isPaymentDateMenuOpen"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                eager
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="paymentDateFormatted"
                    :append-icon="icons.mdiCalendar"
                    readonly
                    dense
                    outlined
                    hide-details
                    class="header-inputs flex-grow-0 mb-6"
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="payment.payment_date"
                  color="primary"
                  :first-day-of-week="1"
                  @input="isPaymentDateMenuOpen = false"
                ></v-date-picker>
              </v-menu>
              <v-text-field
                ref="paymentAmountInput"
                v-model="payment.payment_amount"
                outlined
                dense
                min="0"
                type="number"
                placeholder="Payment Amount"
                prefix="BND$"
                hide-details="auto"
                oninput="if (this.value < 0) this.value = 0;"
                class="mb-6"
                @max-payment="payment.payment_amount = $event; $forceUpdate()"
              >
                <template #append>
                  <v-chip
                    label
                    small
                    color="primary"
                    class="v-chip-light-bg font-weight-semibold primary--text hover-pointer"
                    @click="$refs.paymentAmountInput.$emit('max-payment', payment.max_amount.replace(/[$,]/g, ''))"
                  >
                    MAX
                  </v-chip>
                </template>
              </v-text-field>
              <v-autocomplete
                ref="paymentMethodList"
                v-model="payment.payment_method_id"
                :items="paymentMethods"
                cache-items
                item-text="name"
                item-value="id"
                single-line
                outlined
                dense
                hide-details
                return-object
                placeholder="Select Method"
                class="payment-inputs my-auto flex-grow-0 mb-6"
                @change="payment.payment_method = $event; payment.payment_method_id = $event.id"
              >
                <template #append-item>
                  <div class="pa-0 mt-2 text-center append-select">
                    <v-btn
                      block
                      depressed
                      color="primary"
                      class="rounded-0"
                      @click="addPaymentMethodDialog = true"
                    >
                      Add new payment method
                    </v-btn>
                  </div>
                </template>
              </v-autocomplete>
              <v-textarea
                v-model="payment.note"
                outlined
                rows="3"
                label="Notes"
              ></v-textarea>
            </v-form>
          </v-card-text>

          <v-card-actions>
            <v-btn
              color="primary"
              block
              :loading="savePaymentLoading"
              :disabled="savePaymentLoading || !savePaymentValid"
              @click="savePayment"
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- Duplicate dialog -->
      <v-dialog
        v-model="isDuplicateDialogVisible"
        width="500"
      >
        <v-card>
          <v-card-title class="d-flex align-center mv-4">
            Duplicate {{ bill.bill_number }}?
            <v-spacer></v-spacer>
            <v-btn
              icon
              small
              @click="isDuplicateDialogVisible = false"
            >
              <v-icon size="22">
                {{ icons.mdiClose }}
              </v-icon>
            </v-btn>
          </v-card-title>

          <v-card-text>
            This bill will be duplicated. It copy all data present in this current bill including line items.
          </v-card-text>

          <v-card-actions>
            <v-btn
              color="primary"
              block
              :loading="duplicateLoading"
              :disabled="duplicateLoading"
              class="mt-3"
              @click="duplicateBill()"
            >
              Make another copy
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- Void dialog -->
      <v-dialog
        v-model="isVoidDialogVisible"
        width="500"
      >
        <v-card>
          <v-card-title class="d-flex align-center mv-4">
            Void this bill?
            <v-spacer></v-spacer>
            <v-btn
              icon
              small
              @click="isVoidDialogVisible = false"
            >
              <v-icon size="22">
                {{ icons.mdiClose }}
              </v-icon>
            </v-btn>
          </v-card-title>

          <v-card-text>
            This bill will be voided. It won't be taken into any calculations.
          </v-card-text>

          <v-card-actions>
            <v-btn
              color="error"
              block
              :loading="voidLoading"
              :disabled="voidLoading"
              class="mt-3"
              @click="voidBill()"
            >
              Remove
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- Add Payment Method Dialog -->
      <v-dialog
        v-model="addPaymentMethodDialog"
        width="500"
        persistent
      >
        <v-card>
          <v-card-title class="d-flex align-center mv-4 mb-4">
            New Payment Method
            <v-spacer></v-spacer>
            <v-btn
              icon
              small
              @click="addPaymentMethodDialog = false; savePaymentMethodForm.reset()"
            >
              <v-icon size="22">
                {{ icons.mdiClose }}
              </v-icon>
            </v-btn>
          </v-card-title>

          <v-card-text>
            <v-form
              ref="savePaymentMethodForm"
              v-model="savePaymentMethodValid"
            >
              <v-text-field
                v-model="newPaymentMethod.name"
                outlined
                dense
                label="Name"
                :rules="[validators.required]"
                hide-details="auto"
                class="mb-6"
              ></v-text-field>
            </v-form>
          </v-card-text>

          <v-card-actions>
            <v-btn
              color="primary"
              block
              :loading="savePaymentMethodLoading"
              :disabled="savePaymentMethodLoading || !savePaymentMethodValid"
              @click="savePaymentMethod"
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-card-text class="pt-5">
        <v-row>
          <v-col
            cols="4"
            sm="2"
          >
            <div class="d-flex align-center">
              <v-select
                v-model="tablePagination.per"
                :items="listLengthOptions"
                single-line
                outlined
                dense
                hide-details
                class="bill-list-row-selection"
                @input="listLengthChange()"
              ></v-select>
              <div class="pl-3">
                Rows
              </div>
            </div>
          </v-col>

          <v-spacer></v-spacer>

          <v-col
            sm="10"
            cols="4"
            class="d-flex justify-end"
          >
            <v-pagination
              v-model="tablePagination.current_page"
              total-visible="6"
              :length="tablePagination.total_page"
              @input="fetchBills"
            ></v-pagination>
          </v-col>
        </v-row>
      </v-card-text>

      <template v-if="bills.length !== 0">
        <v-divider class="mb-4"></v-divider>
        <v-row>
          <v-col>
            <v-btn
              color="primary"
              :loading="printLoading"
              :disabled="printLoading"
              class="ml-5 mb-1"
              @click="printReport"
            >
              <span>Print as CSV</span>
            </v-btn>
          </v-col>
        </v-row>
      </template>
    </v-card>
  </div>
</template>

<script>
import {
  mdiPlus,
  mdiCheckCircleOutline,
  mdiAlertCircleOutline,
  mdiContentCopy,
  mdiDeleteOutline,
  mdiPencilOutline,
  mdiCalendar,
  mdiPrinter,
  mdiMagnify,
  mdiClose,
  mdiDotsVertical,
  mdiChartTimelineVariant,
} from '@mdi/js'
import {
  ref,
  inject,
  computed,
  onMounted,
  watch,
} from '@vue/composition-api'
import { required } from '@core/utils/validation'
import { saveAs } from 'file-saver'

export default {
  setup() {
    // Initializers
    const store = inject('store')
    const snackbarService = inject('snackbarService')
    const utilsService = inject('utilsService')

    // Properties
    const [dayNow, monthNow, yearNow] = new Date().toLocaleDateString('en-SG').split('/')
    const bills = ref([])
    const bill = ref({})

    const searchText = ref('')
    const statusFilter = ref(null)
    const statusOptions = ref(['Outstanding', 'Paid', 'Partially Paid'])
    const dateRange = ref([`${yearNow}-${monthNow}-01`, `${yearNow}-${monthNow}-${dayNow}`])
    const isDateMenuOpen = ref(false)
    const firstRecord = ref(null)

    const payment = ref({
      expense_bill_id: null,
      payment_date: `${yearNow}-${monthNow}-${dayNow}`,
    })
    const savePaymentValid = ref(false)
    const savePaymentLoading = ref(false)
    const isAddNewPaymentDialogOpen = ref(false)
    const isPaymentDateMenuOpen = ref(false)

    const paymentMethods = ref([])
    const newPaymentMethod = ref({})
    const addPaymentMethodDialog = ref(false)
    const savePaymentMethodForm = ref(null)
    const savePaymentMethodValid = ref(false)
    const savePaymentMethodLoading = ref(false)

    const isVoidDialogVisible = ref(false)
    const voidLoading = ref(false)
    const isDuplicateDialogVisible = ref(false)
    const duplicateLoading = ref(false)
    const printLoading = ref(false)

    // Table Handlers
    const loading = ref(false)
    const expanded = ref([])
    const options = ref({
      sortBy: ['date_issued'],
      sortDesc: [true],
    })
    const listLengthOptions = ref([20, 50, 100])
    const tablePagination = ref({
      current_page: 1,
      per: 20,
      total_count: 0,
      total_page: 0,
    })
    const tableHeaders = computed(() => (
      [
        { text: 'VENDOR', value: 'vendor_company' },
        {
          text: 'STATUS',
          value: 'status',
          align: 'center',
          sortable: false,
        },
        { text: 'DATE ISSUED', value: 'date_issued' },
        { text: 'DUE DATE', value: 'due_date' },
        { text: 'TOTAL', value: 'total' },
        { text: 'AMOUNT DUE', value: 'amount_due' },
        { text: 'NOTES', value: 'note', sortable: false },
        {
          text: 'ACTIONS',
          value: 'actions',
          align: 'center',
          sortable: false,
        },
      ]
    ))
    const tableSmHeaders = computed(() => (
      [
        { text: 'VENDOR', value: 'vendor_company' },
        { text: 'STATUS', value: 'status', sortable: false },
        { text: 'TOTAL', value: 'total' },
        { text: '', value: 'data-table-expand' },
      ]
    ))

    // Computed
    const dateRangeText = computed(() => utilsService.dateRangeText(dateRange.value))
    const paymentDateFormatted = computed(() => utilsService.formatDate(payment.value.payment_date))

    // Methods
    const fetchBills = () => {
      loading.value = true
      store
        .dispatch('billStore/fetchBills', {
          page: tablePagination.value.current_page,
          per: tablePagination.value.per,
          sort_by: options.value.sortBy[0],
          sort_dir: options.value.sortDesc[0] ? 'desc' : 'asc',
          search_text: searchText.value,
          start_date: dateRange.value[0],
          end_date: dateRange.value[1] ? dateRange.value[1] : dateRange.value[0],
          status: statusFilter.value,
        })
        .then(response => {
          const { data, pagination, first } = response.data
          bills.value = data
          tablePagination.value = pagination
          firstRecord.value = first
          loading.value = false
        })
        .catch(error => {
          loading.value = false
          snackbarService.error(error.response.data.message || 'Something went wrong while fetching bills. Please refresh!')
        })
    }
    const fetchPaymentMethods = () => {
      store
        .dispatch('knowledgeBaseStore/fetchPaymentMethods', { no_pagination: true })
        .then(response => {
          paymentMethods.value = response.data.data
        })
        .catch(error => {
          snackbarService.error(error.response.data.message || 'Something went wrong while fetching payment methods. Please refresh!')
        })
    }
    const listLengthChange = () => {
      tablePagination.value.current_page = 1
      fetchBills()
    }
    const dateRangeChange = () => {
      isDateMenuOpen.value = false
      tablePagination.value.current_page = 1
      fetchBills()
    }
    const duplicateBill = () => {
      duplicateLoading.value = true
      store
        .dispatch('billStore/duplicateBill', { id: bill.value.id })
        .then(response => {
          snackbarService.success(response.data.message)
          fetchBills()
          isDuplicateDialogVisible.value = false
          duplicateLoading.value = false
        })
        .catch(error => {
          duplicateLoading.value = false
          snackbarService.error(error.response.data.message || 'Something went wrong while duplicating bill. Please refresh!')
        })
    }
    const voidBill = () => {
      voidLoading.value = true
      store
        .dispatch('billStore/voidBill', { id: bill.value.id })
        .then(response => {
          snackbarService.success(response.data.message)
          fetchBills()
          isVoidDialogVisible.value = false
          voidLoading.value = false
        })
        .catch(error => {
          voidLoading.value = false
          snackbarService.error(error.data.message || 'Something went wrong while voiding bill. Please refresh!')
        })
    }
    const printReport = () => {
      printLoading.value = true
      store
        .dispatch('billStore/printReport', {
          search_text: searchText.value,
          start_date: dateRange.value[0],
          end_date: dateRange.value[1] ? dateRange.value[1] : dateRange.value[0],
          status: statusFilter.value,
        })
        .then(response => {
          saveAs(response, `bill(${dateRangeText.value}).csv`)
          printLoading.value = false
        })
        .catch(error => {
          printLoading.value = false
          snackbarService.error(error.response.data.message || 'Something went wrong while printing report. Please refresh!')
        })
    }
    const savePaymentMethod = () => {
      savePaymentMethodLoading.value = true
      store
        .dispatch('knowledgeBaseStore/createPaymentMethod', newPaymentMethod.value)
        .then(response => {
          snackbarService.success(response.data.message)
          addPaymentMethodDialog.value = false
          savePaymentMethodLoading.value = false
          savePaymentMethodForm.value.reset()
          fetchPaymentMethods()

          payment.value.payment_method = response.data.data.name
          payment.value.payment_method_id = response.data.data.id
        })
        .catch(error => {
          savePaymentMethodLoading.value = false
          snackbarService.error(error.response.data.message || 'Something went wrong while adding payment method. Please refresh!')
        })
    }
    const savePayment = () => {
      savePaymentLoading.value = true
      store
        .dispatch('billStore/createPayment', payment.value)
        .then(response => {
          snackbarService.success(response.data.message)
          isAddNewPaymentDialogOpen.value = false
          savePaymentLoading.value = false
          fetchBills()

          payment.value = {
            payment_date: `${yearNow}-${monthNow}-${dayNow}`,
          }
        })
        .catch(error => {
          savePaymentLoading.value = false
          snackbarService.error(error.response.data.message || 'Something went wrong while saving payment. Please refresh!')
        })
    }
    const billStatus = status => {
      let variantAndIcon = {}
      switch (status) {
        case 'Fully Paid':
          variantAndIcon = { variant: 'success', icon: mdiCheckCircleOutline }
          break
        case 'Partially Paid':
          variantAndIcon = { variant: 'warning', icon: mdiChartTimelineVariant }
          break
        case 'Outstanding':
          variantAndIcon = { variant: 'error', icon: mdiAlertCircleOutline }
          break
        default:
          variantAndIcon = { variant: 'secondary', icon: mdiClose }
      }

      return variantAndIcon
    }

    // Watch
    let timer = null
    watch([searchText, statusFilter, options], () => {
      if (timer) {
        clearTimeout(timer)
        timer = null
      }
      timer = setTimeout(() => {
        fetchBills()
      }, 200)
    })

    // Mounted
    onMounted(() => fetchPaymentMethods())

    return {
      // Computed
      dateRangeText,
      paymentDateFormatted,

      // Properties
      bills,
      bill,
      dayNow,
      monthNow,
      yearNow,

      searchText,
      statusFilter,
      statusOptions,
      dateRange,
      isDateMenuOpen,
      firstRecord,

      payment,
      savePaymentValid,
      savePaymentLoading,
      isAddNewPaymentDialogOpen,
      isPaymentDateMenuOpen,

      paymentMethods,
      newPaymentMethod,
      savePaymentMethodForm,
      savePaymentMethodValid,
      savePaymentMethodLoading,
      addPaymentMethodDialog,

      isVoidDialogVisible,
      voidLoading,
      isDuplicateDialogVisible,
      duplicateLoading,
      printLoading,

      // Table Handlers
      loading,
      expanded,
      options,
      listLengthOptions,
      tablePagination,
      tableHeaders,
      tableSmHeaders,

      // Validators
      validators: {
        required,
      },

      // Icons
      icons: {
        mdiPlus,
        mdiCheckCircleOutline,
        mdiAlertCircleOutline,
        mdiContentCopy,
        mdiDeleteOutline,
        mdiPencilOutline,
        mdiCalendar,
        mdiPrinter,
        mdiMagnify,
        mdiClose,
        mdiDotsVertical,
      },

      // Methods
      fetchBills,
      listLengthChange,
      dateRangeChange,
      duplicateBill,
      voidBill,
      printReport,
      savePaymentMethod,
      savePayment,
      billStatus,
    }
  },
}
</script>
